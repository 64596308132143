// import React, { useRef, useState, useEffect } from 'react'
import React from 'react'

// NOTE, animations are WIP..
// const TRANSITION_DURATION = '500'

const AccordionItem = ({ data, index, active, toggleActive }) => {
  // const container = useRef(null)
  // const [height, setHeight] = useState(0);

  // useEffect(() => {
  //   function handleActive(active) {
  //     if (!container.current) return;
  //     const containerHeight = container.current.getBoundingClientRect().height;
  //     if (active) {
  //       setHeight(`${containerHeight}px`)
  //       setTimeout(() => {
  //         setHeight(`auto`)
  //       }, TRANSITION_DURATION);
  //     }
  //     if (!active) {
  //       setHeight(`${containerHeight}px`)
  //       setTimeout(() => {
  //         setHeight(0)
  //       }, 25)
  //     }
  //   }
  //   handleActive(active)
  // }, [active, container])

  function handleClick(index) {
    toggleActive(active ? false : index)
  }

  return (
    <div className="border-b border-grey-lighter ">
      {data.heading && (
        <button
          className="w-full flex items-center py-3 cursor-pointer text-left focus:outline-none"
          onClick={() => handleClick(index)}
        >
          <div className="w-4 h-4 flex-shrink-0 relative">
            <svg
              className="block w-full h-full fill-current"
              viewBox="0 0 17 17"
            >
              {!active && <polyline points="7.75 0 9.25 0 9.25 17 7.75 17" />}
              <rect y="7.75" width="17" height="1.5" />
            </svg>
          </div>
          <div className="pl-4 tracking-wide">{data.heading}</div>
        </button>
      )}
      {data.body.html && active && (
        <div
          // style={{'height': height}}
          className="text-grey-alt pl-5"
          // className={`text-grey-alt pl-5 overflow-hidden transition-all duration-${TRANSITION_DURATION}`}
        >
          <div
            // ref={container}
            className="rte pl-4 pb-4"
            dangerouslySetInnerHTML={{
              __html: data.body.html,
            }}
          />
        </div>
      )}
    </div>
  )
}

export default AccordionItem
