import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import Layout from '../components/Layout'
import Accordion from '../components/Accordion'

const PrivacyPolicyPage = ({ data, location }) => {
  const pageData = data.prismicPrivacyPolicy.data
  return (
    <Layout
      seoTitle={pageData.meta_title || pageData.title}
      seoDescription={pageData.meta_description}
      location={location}
    >
      <div className="wrapper pt-32 md:pt-68 md:pb-26 px-ogs">
        <Fade ssrReveal delay={250} duration={2250} bottom distance={'25px'}>
          {pageData.title && (
            <h1 className="text-xl lg:text-2xl font-condensed uppercase tracking-wider text-center mb-14 md:mb-30">
              {pageData.title}
            </h1>
          )}
        </Fade>
        <Fade ssrReveal delay={250} duration={2250}>
          <div className="md:flex md:space-x-gs">
            {pageData.lead.html && (
              <div className="w-full md:w-1/2 mb-20">
                <div className="md:w-5/6">
                  <div
                    className="rte"
                    dangerouslySetInnerHTML={{
                      __html: pageData.lead.html,
                    }}
                  />
                </div>
              </div>
            )}
            <div className="w-full md:w-1/2">
              <Accordion data={pageData.accordion} />
            </div>
          </div>
        </Fade>
      </div>
    </Layout>
  )
}

PrivacyPolicyPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default PrivacyPolicyPage

export const query = graphql`
  query {
    prismicPrivacyPolicy {
      data {
        meta_description
        meta_title
        title
        lead {
          html
        }
        accordion {
          heading
          body {
            html
          }
        }
      }
    }
  }
`
