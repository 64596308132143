import React, { useState } from 'react'
import Item from './AccordionItem'

const Accordion = ({ data }) => {
  const [activeItem, setActiveItem] = useState(null)

  function toggleActiveItem(test) {
    setActiveItem(test)
  }

  return (
    <div className="border-t border-grey-lighter">
      {data
        .filter(item => item.heading)
        .map((item, index) => (
          <Item
            key={index}
            index={index}
            data={item}
            active={activeItem === index}
            toggleActive={toggleActiveItem}
          />
        ))}
    </div>
  )
}

export default Accordion
